.text-with-line {
    display: flex;
    align-items: center;
    }

.text {
    margin-right: 10px;
    }
 
.line {
    flex-grow: 1;
    height: 1px;
    background-color: #086AB4;
    margin-top: 15px;
    height: 2px;
    }

.analysis-card-header:after {
    content: "\F286";
    font-family: 'bootstrap-icons';
    text-align: right;
    float: right;
}

.analysis-card-header.collapsed:after {
    content: "\F282";
    font-family: 'bootstrap-icons';
}
