@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$primary: #086AB4; // set the $primary variable

// merge with existing $theme-colors map
$theme-colors: map-merge($theme-colors, (
  "primary": $primary
));
// Enable negative margins
$enable-negative-margins: true;

// set changes
@import "node_modules/bootstrap/scss/bootstrap";

.btn-outline-primary {
  border: 1px solid #ced4da;
  color: #000;
}

.tablink {
 border: 1px solid #B9B9B9;
 border-radius: 30px;
 background-color: #FFFFFF;
 margin: 5px;
 font-size: 17px;
 color: inherit;
 text-decoration: inherit;
 padding: 10px;
}

.tablink.active {
 font-weight: bold;
 background-color: #086AB4;
 color: white;
}

.names {
 text-align: center;
 margin: 20px;
}

.form-select:disabled {
  background-color: white;
  background-image: none;
}

.form-control:disabled {
  background-color: white;
}

.hide-spinner {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Other modern browsers */
}

.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Remove default margin */
}